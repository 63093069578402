<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <v-chart :style="{ height: height + 'px' }" :option="option" autoresize />
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent } from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';

use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent]);

export default {
  name: 'EchartPie',
  components: {
    VChart
  },
  props: {
    chartData: { type: Array, default: null },
    chartTitle: { type: String, default: '' },
    heightPie: { type: Number, default: 400 }
  },
  provide: {
    [THEME_KEY]: 'vintage'
  },
  data() {
    return {
      height: this.heightPie,
      option: {
        title: {
          text: this.chartTitle,
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{c} ({d}%)'
        },
        color: ['#1d2f47', '#777777', '#99cccc', '#37A8DF', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'bottom',
          feature: {
            mark: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            name: 'Traffic Sources',
            type: 'pie',
            radius: '70%',
            center: ['50%', '50%'],
            data: this.chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    };
  }
};
</script>

<style scoped>
.chart {
  height: 420px;
}
</style>
