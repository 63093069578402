<template>
  <form class="flex flex-row gap-2 w-full" @submit.prevent>
    <div
      id="filter"
      class="flex flex-row gap-2 w-full"
    >
      <div class="relative w-1/2">
        <input 
          v-model="textFilter"
          type="text"
          placeholder="Recherche multicritère"
          title="Recherche par nom de projet, opérateur, insee, commentaire"
          class="text-sm w-full px-3 py-2 rounded border border-gray-300 shadow-inner"
          required
          :disabled="isLocationMode"
          :class="{
            'bg-gray-100 text-gray-300 placeholder-gray-400 text-sm cursor-not-allowed': isLocationMode
          }"
          @keydown.enter="emitFilter"
        >
      </div>

      <div class="w-1/2">
        <date-picker
          v-model="periodFilter"
          type="date"
          placeholder="Sélectionner deux dates"
          class="custom-date-picker"
          range
          :disabled="isLocationMode"
          @change="handleDateChange"
        />
      </div>
    </div>

    <button
      type="button"
      class="w-10 h-10 shadow-raphal rounded-md border border-raphal-valider flex items-center bg-white text-raphal-valider cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed disabled:text-raphal-valider focus:outline-none"
      :disabled="isLocationMode"
      @click="emitFilter"
    >
      <span class="text-center w-full">
        <svg
          aria-hidden="true"
          width="20"
          height="35pt"
          class="w-full p-1 text-raphal-valider"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </button>

    <button ref="buttonLocation"
            type="button"
            class="w-10 h-10 mx-1 shadow-raphal rounded-md border border-raphal-valider flex items-center bg-white text-raphal-valider cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed disabled:text-raphal-valider focus:outline-none"
            @click="toggleLocation"
    >
      <span class="text-center w-full">
        <svg v-if="!isLocationMode" class="w-full p-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19s-7-4.4-7-10a7 7 0 1 1 14 0c0 5.6-7 10-7 10z" />
          <circle cx="10" cy="9" r="1" />
        </svg>

        <svg v-else class="w-full p-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19s-7-4.4-7-10a7 7 0 1 1 14 0c0 5.6-7 10-7 10z" />
  
          <line x1="7" y1="7" x2="13" y2="13" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
  
          <line x1="7" y1="13" x2="13" y2="7" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
        </svg>
      </span>
    </button>
  </form>
</template>
  
<script>
  import DatePicker from 'vue2-datepicker';

  export default {
    name: 'MultiFilterComponent',
    components: {
      DatePicker,
    },
    data() {
      return {
        isLocationMode: false,
        textFilter: '',
        periodFilter: ''
      };
    },
    mounted() {
      window.addEventListener('keydown', this.handleKeydown);
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeydown);
    },
    methods: {
      handleDateChange(newDates) {
        if (!newDates || newDates.length === 2) {
          this.emitFilter();
        }
      },
      toggleLocation() {
          this.isLocationMode = !this.isLocationMode;
          this.$parent.$refs.mapcontainer.isLocationMode = !this.$parent.$refs.mapcontainer.isLocationMode;

          if (this.isLocationMode) {
            this.$parent.flashMap = true;
            setTimeout(() => {
              this.$parent.flashMap = false;
            }, 3000);
          } else {
            this.$emit('filter', 'search');
          }
      },
      handleKeydown(event) {
        if (event.key === 'Escape' && this.isLocationMode) {
          this.isLocationMode = false;
          this.$parent.$refs.mapcontainer.isLocationMode = !this.$parent.$refs.mapcontainer.isLocationMode;
          this.$emit('filter', 'search');
        }
      },
      emitFilter() {
        this.$parent.textFilter = this.textFilter;
        this.$parent.period = this.periodFilter;
        this.$emit('filter', 'search');
      },
    },
  };
</script>

<style scoped>
.custom-date-picker::v-deep .mx-input {
  height: auto;
  padding: 0.57rem;
}
.custom-date-picker {
  width: 100% !important;
}
.custom-date-picker::v-deep .mx-input:disabled::placeholder {
  color: #d1d5db;
}
.shadow-inner {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
}
</style>