<template>
  <form 
    class="flex justify-center"
    @submit.prevent=""
  >
    <div class="flex flex-col gap-4 p-6">
      <span v-if="isViewing" class="flex justify-center text-center items-center font-bold text-xl text-gray-900">Informations log</span>
      <span v-if="isAdding" class="flex justify-center text-center items-center font-bold text-xl text-gray-900">Création log</span>
      <span v-if="isEditing" class="flex justify-center text-center items-center font-bold text-xl text-gray-900">Edition log</span>
      <div class="flex flex-col">
        <label for="title" class="block text-gray-700 text-lg font-bold">Titre</label>
        <span v-if="isViewing" class="text-sm font-semibold"> {{ title }} </span>
        <input
          v-if="isChanging"
          id="title"
          v-model="title"
          type="text"
          class="form-input mt-1 block w-full border rounded-md shadow-sm"
        >
      </div>

      <div class="flex flex-col">
        <label for="content" class="block text-gray-700 text-lg font-bold">Contenu</label>
        <span v-if="isViewing" class="text-sm font-semibold"> {{ content }} </span>
        <textarea
          v-if="isChanging"
          id="content"
          v-model="content"
          rows="4"
          class="form-textarea mt-1 block w-full border rounded-md shadow-sm"
        />
      </div>

      <!-- <div class="flex flex-col">
        <label for="date" class="block text-gray-700 text-lg font-bold">Date</label>
        <span v-if="isViewing" class="text-sm font-semibold"> {{ date }} </span>
        <input
          v-if="isChanging"
          id="date"
          v-model="date"
          type="date"
          class="form-input mt-1 block w-full border rounded-md shadow-sm"
        >
      </div> -->

      <div class="flex flex-col">
        <span class="block text-gray-700 text-lg font-bold">Type</span>
        <div class="flex items-center">
          <label class="inline-flex items-center mr-4">
            <input
              v-model="type"
              type="radio"
              name="type"
              value="MAJOR"
              :disabled="isViewing || isEditing"
              class="form-radio"
            >
            <span class="ml-2 text-sm font-semibold">Majeur</span>
          </label>
          <label class="inline-flex items-center mr-4">
            <input
              v-model="type"
              type="radio"
              name="type"
              value="MINOR"
              :disabled="isViewing || isEditing"
              class="form-radio"
            >
            <span class="ml-2 text-sm font-semibold">Mineur</span>
          </label>
          <label class="inline-flex items-center">
            <input
              v-model="type"
              type="radio"
              name="type"
              value="PATCH"
              :disabled="isViewing || isEditing"
              class="form-radio"
            >
            <span class="ml-2 text-sm font-semibold">Patch</span>
          </label>
        </div>
      </div>

      <div v-if="isAdmin" class="flex flex-row justify-center gap-4">
        <button
          v-if="isChanging"
          class="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
          @click="handleSubmit"
        >
          Enregistrer
        </button>
        <button
          v-if="isViewing"
          class="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
          @click="addMode"
        >
          Ajouter
        </button>
        <button
          v-if="isViewing"
          class="text-yellow-400 hover:text-white border border-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-yellow-300 dark:text-yellow-300 dark:hover:text-white dark:hover:bg-yellow-400 dark:focus:ring-yellow-900"
          @click="editMode"
        >
          Editer
        </button>
        <button
          v-if="isChanging"
          class="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
          @click="cancel"
        >
          Annuler
        </button>
      </div>
    </div>
  </form>
</template>
  
<script>
  import { mapGetters } from 'vuex';

  import { apolloProjectClient } from '../vue-apollo';
  import gql from 'graphql-tag';

  const CREATE_CHANGELOG_MUTATION = gql`
    mutation CreateLogJson($title: String!, $content: String!, $type: String!) { # $createdAt: DateTime!,
      createLogJson(
        title: $title
        content: $content
        # createdAt: $createdAt
        type: $type) {
          ok
          changelog {
            id
            title
            content
            # createdAt
            type
          }
      }
    }
  `;

  const UPDATE_CHANGELOG_MUTATION = gql`
    mutation UpdateLogJson($id: Int!, $title: String, $content: String, $type: String) { # $createdAt: DateTime, 
      updateLogJson(
        id: $id
        title: $title
        content: $content
        # createdAt: $createdAt
        type: $type
      ) {
        ok
        changelog {
          id
          title
          content
           # createdAt
          type
        }
      }
    }
  `;

  const Toast = window.Toast
  
  export default {
    name: 'MainComponentChangeLog',
    data() {
        return {
          title: '',
          content: '',
          //date: '',
          type: 'MAJOR',
          isAdding: false,
          isEditing: false,
        };
    },
    computed: {
        ...mapGetters({
          user: 'Auth/user',
          role: 'Auth/roleUser'
        }),
        logId() {
          return this.$route.params.log_id;
        },
        userId() {
          return this.user.id;
        },
        isAdmin() {
          return this.role === 'admin';
        },
        isViewing() {
          return !this.isAdding && !this.isEditing;
        },
        isChanging() {
          return this.isAdding || this.isEditing;
        },
    },
    watch: {
        type(newVal) {
          this.localType = newVal;
        },
        localType(newVal) {
          this.$emit('update:type', newVal);
        },
        logId: 'getLogById'
    },
    created() {
      this.getLogById();
    },
    mounted() {
      this.localType = this.type;
      this.getLogById();
    },
    methods: {
        async handleSubmit() {
          if (this.userId) {
            // const date = new Date();
            // const newDate = this.formatDate(date);

            if (this.title.trim() == '' || this.content.trim() == '') {
              Toast.fire({
                  icon: 'error',
                  title: 'Veuillez remplir tous les champs!'
                });
              return;
            }

            if(this.isAdding) {
              const { data } = await apolloProjectClient.mutate({
                mutation: CREATE_CHANGELOG_MUTATION,
                variables: {
                  title: this.title,
                  content: this.content,
                  //createdAt: newDate,
                  type: this.localType,
                },
              });

              if (data.createLogJson.ok) {
                Toast.fire({
                  icon: 'success',
                  title: 'Log créé avec succès!'
                });
                //localStorage.setItem(`lastUpdate_${this.userId}`, newDate);
                Object.keys(localStorage).forEach(key => {
                  if (key.startsWith('popupSeen_')) {
                    localStorage.removeItem(key);
                  }
                });
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              } else {
                Toast.fire({
                  icon: 'error',
                  title: 'Erreur lors de la création!'
                });
                console.error('Failed to create log : ', data.createLog);
              }
            }

            if(this.isEditing) {
              const { data } = await apolloProjectClient.mutate({
                mutation: UPDATE_CHANGELOG_MUTATION,
                variables: {
                  id: this.logId,
                  title: this.title,
                  content: this.content,
                  //createdAt: newDate,
                  type: this.localType
                },
              });

              if (data.updateLogJson.ok) {
                Toast.fire({
                  icon: 'success',
                  title: 'Log mis à jour avec succès!'
                });
                //localStorage.setItem(`lastUpdate_${this.userId}`, newDate);
                Object.keys(localStorage).forEach(key => {
                  if (key.startsWith('popupSeen_')) {
                    localStorage.removeItem(key);
                  }
                });
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              } else {
                Toast.fire({
                  icon: 'error',
                  title: 'Erreur lors de la mise à jour!'
                });
                console.error('Failed to update log:', data.updateLog);
              }
            }
          }
        },
        addMode() {
          // const currentDate = new Date()
          // const formattedDate = currentDate.toISOString().split('T')[0];
          // this.date = formattedDate;

          this.title = '';
          this.content = '';
          this.type = 'MAJOR';

          this.isAdding = true;
          this.isEditing = false;
        },
        editMode() {
          this.isEditing = true;
          this.isAdding = false;
        },
        cancel() {
          this.getLogById();

          this.isEditing = false;
          this.isAdding = false;
        },
        formatDate(date) {
            if (!(date instanceof Date)) {
                date = new Date(date);
            }
            
            if (isNaN(date.getTime())) {
                throw new Error('Invalid Date');
            }

            const pad = (num) => num.toString().padStart(2, '0');
            const padMs = (num) => num.toString().padStart(6, '0');

            const year = date.getUTCFullYear();
            const month = pad(date.getUTCMonth() + 1);
            const day = pad(date.getUTCDate());
            const hours = pad(date.getUTCHours());
            const minutes = pad(date.getUTCMinutes());
            const seconds = pad(date.getUTCSeconds());
            const milliseconds = padMs(date.getUTCMilliseconds() * 1000);

            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;
        },
        async getLogById() {
          try {
            let { data: data } = await apolloProjectClient.query({
              query: gql`
                query {
                  logById(id: ${this.logId}) {
                    id
                    title
                    content
                    # createdAt
                    type
                  }
                }
              `,
              fetchPolicy: 'network-only'
            });

            const logById = data.logById;

            if (data && logById) {
              this.title = logById.title;
              this.content = logById.content;
              //this.date = logById.createdAt.slice(0, 10);
              this.type = logById.type;
            }
          } catch (err) {
            console.log('Erreur lors de la récupération des données : ', err)
          }
        }
    },
  };
</script>

<style scoped>
.form-input,
.form-textarea {
  @apply border-gray-300 rounded-md shadow-sm;
}

.form-radio {
  /* Set the size of the radio button */
  width: 14px;
  height: 14px;

  /* Adjust the appearance of the radio button */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Add border and background color */
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  outline: none;
}

.form-radio:checked {
  /* Set the color of the checked radio button to black */
  background-color: black;
  border-color: black;
  position: relative;
}

.form-radio:checked::before {
  /* Add a smaller inner dot for the checked state */
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 3px;
  left: 3px;
}

/* Remove focus ring (blue outline) */
.form-radio:focus {
  outline: none;
  box-shadow: none;
}

.form-radio:disabled {
  cursor: not-allowed;
}
</style>
  