<template>
  <div>
    <select
      v-model="scoreOperator"
      class="w-1/4 shadow appearance-none border text-xl border-gray-1 rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    >
      <option disabled value="">
        Opérateur
      </option>
      <option>&gt;</option>
      <option>&gt;=</option>
      <option>=</option>
      <option>&lt;=</option>
      <option>&lt;</option>
    </select>
    <input
      v-model="scoreValue"
      class="w-2/4 shadow appearance-none border text-xl border-gray-1 rounded py-1 px-3 ml-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      type="text"
      placeholder=""
      @keyup.enter="launchSearch"
    >
  </div>
</template>

<script>
import EventBus from '../event-bus';

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    operateur: {
      type: String,
      default: ''
    },
    valuOperateur: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      scoreOperator: this.operateur,
      scoreValue: this.valuOperateur
    };
  },
  computed: {
    model: {
      get() {
        return {};
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    scoreOperator: function (val) {
      let data = {
        operator: val,
        value: this.scoreValue
      };
      this.model = JSON.stringify(data);
    },
    scoreValue: function (val) {
      let data = {
        operator: this.scoreOperator,
        value: val || ''
      };
      this.model = JSON.stringify(data);
    }
  },
  methods: {
    launchSearch() {
      EventBus.$emit("callProjectsFilter", { filter: 'search', fromRuleSlot: true });
    }
  }
};
</script>
