<template>
  <div class="h-full w-full flex flex-col">
    <div class="flex flex-col">
      <div class="w-2/6 px-6 pt-6 my-3 flex align-baseline">
        <span
          style="font-family: 'Roboto', sans-serif"
          class="min-w-full text-raphal-valider font-thin mr-5 text-6xl pt-1 align-baseline"
        >
          GESTION
        </span>
      </div>

      <div v-if="role == 'manager'" class="w-1/3 mx-6 mb-3 bg-white border rounded-md p-5">
        <h4 class="font-bold">
          JETONS
        </h4>
        <p class="text-lg m-2 text-gray-600">
          {{ user.usedJetons || 0 }} Jetons consommés
        </p>
        <p class="text-lg m-2 text-gray-600">
          {{ user.totalJetons && user.usedJetons ? user.totalJetons - user.usedJetons : 0 }} Jetons restants
        </p>
      </div>
    </div>

    <div class="flex flex-col flex-grow">
      <div class="sm:hidden px-6">
        <label for="select-tab" class="sr-only">Sélectionner un profil</label>
        <select
          id="select-tab"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          @change="toggleUsersSelect($event)"
        >
          <option
            v-for="item in menuListItems"
            :key="item.id"
            class="flex-1 mr-1 text-center border-l border-t border-r rounded-t"
            :value="item.role"
          >
            {{ item.title }}
          </option>
        </select>
      </div>
      <ul
        class="usr-tab hidden text-sm font-medium text-center text-gray-500 rounded-lg sm:flex dark:divide-gray-700 dark:text-gray-400 px-6"
      >
        <li
          v-for="item in menuListItems"
          :key="item.id"
          :class="['w-full mx-1', item.role === selectedRole ? 'tab-active' : '']"
        >
          <a
            href="#"
            class="text-base text-white font-bold inline-block w-full p-4 bg-raphal-valider border-r border-gray-200 rounded-t dark:border-gray-700 hover:bg-gray-500 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
            @click.prevent="toggleUsersPanel(item.role)"
          >
            {{ item.title }}
          </a>
        </li>
      </ul>

      <div v-if="selectedRole == 'user'" class="h-full flex md:w-full xl:w-full px-6">
        <t-card variant="raphal" class="mb-5 flex flex-col flex-grow">
          <template v-slot:header>
            <div class="items-left text-xl font-bold mx-auto h-8">
              <div class="float-left block text-base">
                <button
                  class="w-full text-center px-3 py-2 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none"
                  @click="addUser('user')"
                >
                  Ajouter
                </button>
              </div>
              <div class="search-box float-left block text-base">
                <input
                  v-model="userFilter['user']"
                  type="text"
                  class="block border border-grey-light w-full px-3 py-2 rounded"
                  placeholder="Rechercher..."
                  @input="updatePagination($event, 'user')"
                >
              </div>
            </div>
          </template>
          <UserManagement ref="userTable" role="user" :nbrows="5" :user-role="role" />
        </t-card>
      </div>

      <div
        v-if="(role == 'manager' || role == 'admin') && selectedRole == 'supervisor'"
        class="h-full flex md:w-full xl:w-full px-6"
      >
        <t-card variant="raphal" class="mb-5 flex flex-col flex-grow">
          <template v-slot:header>
            <div class="items-left text-xl font-bold mx-auto h-8">
              <div class="float-left block text-base">
                <button
                  class="w-full text-center px-3 py-2 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none"
                  @click="addUser('supervisor')"
                >
                  Ajouter
                </button>
              </div>
              <div class="search-box float-left block text-base">
                <input
                  v-model="userFilter['supervisor']"
                  type="text"
                  class="block border border-grey-light w-full px-3 py-2 rounded"
                  placeholder="Rechercher..."
                  @input="updatePagination($event, 'supervisor')"
                >
              </div>
            </div>
          </template>
          <UserManagement ref="supervisorsTable" role="supervisor" :nbrows="10" :user-role="role" />
        </t-card>
      </div>

      <div v-if="role == 'admin' && selectedRole == 'manager'" class="h-full flex-col flex md:w-full xl:w-full px-6">
        <div v-if="selectedRole == 'manager'" class="h-full flex flex-col flex-grow">
          <t-card variant="raphal" class="mb-5 flex flex-col flex-grow">
            <template v-slot:header>
              <div class="items-left text-xl font-bold mx-auto h-8">
                <div class="float-left block text-base">
                  <button
                    class="w-full text-center px-3 py-2 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none"
                    @click="addUser('manager')"
                  >
                    Ajouter
                  </button>
                </div>
                <div class="search-box float-left block text-base">
                  <input
                    v-model="userFilter['manager']"
                    type="text"
                    class="block border border-grey-light w-full px-3 py-2 rounded"
                    placeholder="Rechercher..."
                    @input="updatePagination($event, 'manager')"
                  >
                </div>
              </div>
            </template>
            <UserManagement ref="managerTable" role="manager" :nbrows="5" :user-role="role" />
          </t-card>
        </div>

        <div v-if="selectedRole == 'supervisor'" class="h-full flex flex-col flex-grow">
          <t-card variant="raphal" class="mb-5 flex flex-col flex-grow">
            <template v-slot:header>
              <div class="items-left text-xl font-bold mx-auto h-8">
                <div class="float-left block text-base">
                  <button
                    class="w-full text-center px-3 py-2 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none"
                    @click="addUser('supervisor')"
                  >
                    Ajouter
                  </button>
                </div>
                <div class="search-box float-left block text-base">
                  <input
                    v-model="userFilter['supervisor']"
                    type="text"
                    class="block border border-grey-light w-full px-3 py-2 rounded"
                    placeholder="Rechercher..."
                    @input="updatePagination($event, 'supervisor')"
                  >
                </div>
              </div>
            </template>
            <UserManagement ref="supervisorsTable" role="supervisor" :nbrows="4" :user-role="role" />
          </t-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import UserManagement from '@/components/UserManagement.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'UsersView',
  components: {
    UserManagement
  },
  data() {
    return {
      userRole: 'user',
      userFilter: {
        user: '',
        supervisor: '',
        manager: ''
      },
      menuListItems: [
        {
          id: 0,
          role: 'user',
          title: 'Utilisateurs'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      role: 'Auth/roleUser'
    }),
    selectedRole() {
      return this.userRole;
    }
  },
  mounted() {
    if (this.role === 'admin' || this.role === 'manager') {
      this.menuListItems.push({
        id: 1,
        role: 'supervisor',
        title: 'Superviseurs'
      });
    }
    if (this.role === 'admin') {
      this.menuListItems.push({
        id: 2,
        role: 'manager',
        title: 'Managers'
      });
    }
  },
  methods: {
    addUser(roleUser) {
      if (roleUser == 'manager') {
        this.$refs.managerTable.$refs.modalformeUser.toggleModal(null, 'Ajouter Manageur', false, 'manager', 2);
      } else if (roleUser == 'supervisor') {
        this.$refs.supervisorsTable.$refs.modalformeUser.toggleModal(
          null,
          'Ajouter Superviseur',
          false,
          'supervisor',
          4
        );
      } else {
        this.$refs.userTable.$refs.modalformeUser.supervisor = this.user.username;
        this.$refs.userTable.$refs.modalformeUser.toggleModal(null, 'Ajouter Utilisateur', false, 'user');
      }
      this.showmodal = true;
    },
    /**
     *
     */
    toggleUsersPanel(userRole) {
      if (userRole) {
        this.userRole = userRole;
      }
      // display the first page on every tab change
      const currentTable = [this.$refs.userTable, this.$refs.supervisorsTable, this.$refs.managerTable].filter(x => x)[0];
      currentTable.$refs.userPagination.currentPage = 1;
    },
    /**
     *
     */
    toggleUsersSelect(event) {
      this.toggleUsersPanel(event.target.value);
    },
    updatePagination(event, role) {
      const tableMap = {
        'user': this.$refs.userTable, 
        'supervisor': this.$refs.supervisorsTable, 
        'manager': this.$refs.managerTable
      };
      tableMap[role].$refs.userPagination.currentPage = 1;
    }
  }
};
</script>

<style scoped>
.usr-tab {
  position: relative;
  top: 1px;
}
.usr-tab .tab-active a {
  color: rgba(17, 24, 39, 1);
  background: #fff;
  border-color: rgb(229, 231, 235);
  border-width: 1px 1px 0 1px;
  cursor: default;
}
.usr-tab .tab-active a:hover {
  color: rgba(17, 24, 39, 1);
}

.search-box {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
}

@media (max-width: 416px) {
  .search-box {
    clear: left;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    margin-right: unset;
    margin-left: unset;
  }
}
</style>
