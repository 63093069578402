<template>
  <div
    v-if="visible"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
  >
    <div class="relative bg-white rounded-lg shadow-lg p-6 w-96">
      <button
        class="absolute top-2 right-8 text-gray-500 hover:text-gray-700"
        @click="close"
      >
        &times;
      </button>
      <h2 class="text-lg font-semibold mb-4">
        {{ title }}
      </h2>
      <p class="text-gray-600 mb-6">
        {{ message }}
      </p>
      <div class="flex justify-end space-x-4">
        <button
          v-if="withCancel"
          class="px-4 py-2 bg-gray-200 text-gray-500 rounded hover:bg-gray-300"
          @click="close"
        >
          Annuler
        </button>
        <button
          class="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-300"
          @click="cancel"
        >
          Non
        </button>
        <button
          class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          @click="confirm"
        >
          Oui
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      message: {
        type: String,
        required: true,
      },
      visible: {
        type: Boolean,
        required: true,
      },
      withCancel: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["just_close", "close", "confirm"],
    methods: {
        close() {
            this.$emit("just_close");
        },
        cancel() {
            this.$emit("close");
        },
        confirm() {
            this.$emit("confirm");
        },
    },
  };
  </script>
  