<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <!--content-->
      <div class="bg-grey-lighter min-h-screen min-w-1/2 flex flex-col">
        <div class="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
            <h1 class="mb-8 text-3xl text-center">
              Téléchargements
            </h1>
            <div class="flex">
              <div v-if="!photosOnly" class="w-1/2">
                <label class="block items-center p-3 mb-4">
                  <!-- <input type="checkbox" class="form-checkbox" checked> -->
                  <input
                    v-model="checkedScenes"
                    type="checkbox"
                    value="laz"
                    class="disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="isLazDisabled"
                  >
                  <span :class="['ml-2', isLazDisabled ? 'text-gray-500 opacity-80 cursor-not-allowed' : '']">
                    LAS
                  </span>
                </label>
                <label class="block items-center p-3 mb-2">
                  <!-- <input type="checkbox" class="form-checkbox" checked> -->
                  <input
                    v-model="checkedScenes"
                    type="checkbox"
                    value="tif"
                    class="disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="isTifDisabled"
                  >
                  <span :class="['ml-2', isTifDisabled ? 'text-gray-500 opacity-80 cursor-not-allowed' : '']">
                    ORTHO
                  </span>
                </label>
              </div>
              <div v-if="!photosOnly" class="w-1/2">
                <label class="block items-center p-3 mb-4">
                  <!-- <input type="checkbox" class="form-checkbox" checked> -->
                  <input
                    v-model="checkedScenes"
                    type="checkbox"
                    value="digit"
                    class="disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="isDigitDisabled"
                  >
                  <span :class="['ml-2', isDigitDisabled ? 'text-gray-500 opacity-80 cursor-not-allowed' : '']">
                    DIGIT
                  </span>
                </label>
                <label class="block items-center p-3 mb-2">
                  <!-- <input type="checkbox" class="form-checkbox" checked> -->
                  <input
                    v-model="checkedScenes"
                    type="checkbox"
                    value="rapport"
                    class="disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="isReportDisabled"
                  >
                  <span :class="['ml-2', isReportDisabled ? 'text-gray-500 opacity-80 cursor-not-allowed' : '']">
                    RAPPORT
                  </span>
                </label>
              </div>
            </div>
            <div class="flex">
              <div class="w-1/3">
                <label class="block items-center p-3 mb-4">
                  <input v-model="checkedScenes" type="checkbox" value="imgs">
                  <span class="ml-2 text-sm">IMAGES</span>
                </label>
              </div>
              <div class="w-2/3">
                <label class="block items-center p-3 mb-2">
                  <input v-model="checkedScenes" type="checkbox" value="photos">
                  <span class="ml-2 text-sm">PHOTOS ANNEXES</span>
                </label>
              </div>
              <!-- <label class="block items-center p-3 mb-0">
                <input v-model="checkedScenes" type="checkbox" value="imgs">
                <span class="ml-2">IMAGES</span>
              </label> -->
            </div>

            <button
              class="w-full text-center py-3 rounded bg-raphal-total text-white hover:bg-raphal-termine focus:outline-none my-1"
              @click="exporte"
            >
              Exporter
            </button>
            <button
              class="w-full text-center py-3 rounded bg-raphal-valider text-white hover:bg-gray-500 focus:outline-none my-1"
              @click="closeForme"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalExport',
  components: {},
  props: {
    refreshTable: { type: Function, default: null }
  },
  data() {
    return {
      showModal: false,
      idProject: null,
      checkedScenes: [],
      projectData: null,
      dis: true,
      photosOnly: false
    };
  },
  computed: {
    ...mapGetters({
      isAuth: 'Auth/isAuth',
      user: 'Auth/user',
      roleName: 'Auth/roleUser'
    }),
    isLazDisabled() {
      return this.$raphalUtils.isLazDisabled(this.projectData);
    },
    isTifDisabled() {
      return this.$raphalUtils.isTifDisabled(this.projectData);
    },
    isDigitDisabled() {
      return this.$raphalUtils.isDigitDisabled(this.projectData);
    },
    isReportDisabled() {
      return this.$raphalUtils.isReportDisabled(this.projectData);
    }
  },
  methods: {
    toggleModal: function (id, projectData, photosOnly=false) {
      this.showModal = !this.showModal;
      this.projectData = projectData;
      this.idProject = id;
      this.$set(this, 'photosOnly', photosOnly);
    },
    cleanForme: function () {
      this.checkedScenes = [];
      this.idProject = null;
    },
    closeForme: function () {
      this.showModal = false;
      this.cleanForme();
    },
    exporte: function () {
      // eslint-disable-next-line
      Toast.fire({
        icon: 'success',
        title: 'Préparation des exports en cours...'
      });
      let query = gql`
        mutation prepareZip($id: Int!, $params: String!) {
          prepareZip(id: $id, params: $params) {
            success
            errors
          }
        }
      `;
      const params = this.checkedScenes;

      this.$apollo
        .mutate({
          mutation: query,
          client: 'apolloProjectClient',
          variables: {
            id: this.idProject,
            params: JSON.stringify(params)
          }
        })
        .then((data) => {
          // Result
          const res = data.data.prepareZip;
          if (res) {
            const errors = JSON.parse(res.errors);
            if (res.success == true) {
              let link = document.createElement('a');
              link.setAttribute('download', 'ZIP_RAPH_' + this.nomProjet + '.zip');
              link.href = window.location.origin + '/api/api_raphal/download/' + this.idProject + '/zip';
              document.body.appendChild(link);
              link.click();
              let message = "Opération effectuée avec succès !";
              const notFound = errors.not_found;
              const k = notFound.length;
              if (k > 0) {
                let files;
                if (k > 1) {
                  const lastFile = notFound.pop();
                  files = notFound.join("', '");
                  files += `' et '${lastFile}`;
                } else {
                  files = notFound.join("', '");
                }
                message += `\nMais les fichiers '${files}' sont introuvables!`;
              }

              // eslint-disable-next-line
              Toast.fire({
                icon: 'success',
                title: message
              });
              // this.refreshTable()
              this.showModal = false;
              this.cleanForme();
            } else {
              let message = errors.message;
              const notFound = errors.not_found;
              const k = notFound.length;

              if (errors.code === 'file_not_found') {
                if (k > 1) {
                  message = `Les fichiers '${notFound.join("', '")}' demandés sont introuvables!`;
                } else {
                  message = `Le fichier '${notFound[0]}' demandé est introuvable!`;
                }
              }
              // eslint-disable-next-line
              Toast.fire({
                icon: 'error',
                title: message
              });
            }
          } else {
            // eslint-disable-next-line
            Toast.fire({
              icon: 'error',
              title: 'Erreur !'
            });
          }
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    }
  }
};
</script>
