<template>
  <div class="relative w-full overflow-x-auto shadow-md sm:rounded-t-lg">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" class="w-1/3 text-center px-6 py-3">
            Utilisateur
          </th>
          <th scope="col" class="w-1/3 text-center px-6 py-3">
            <div class="flex justify-center items-center text-center gap-2">
              Nombre de projets
              <a @click="sortByProjects"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
              </svg></a>
            </div>
          </th>
          <th scope="col" class="w-1/3 items-center text-center px-6 py-3">
            <div class="flex justify-center items-center text-center gap-2">
              Pourcentage de réussite
              <a @click="sortByScore"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
              </svg></a>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <div
          v-if="fetching"
          class="h-full w-full flex absolute flex-grow rounded-md z-10 p-4 justify-center opacity-50 text-xl text-center font-bold text-white items-center bg-raphal-valider"
        >
          <svg
            class="w-1/5 animate-spin h-9 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
        <tr
          v-for="project in paginatedProjects"
          :key="project.id"
          class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
        >
          <th scope="row" class="text-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {{ project.fullName }}
          </th>
          <td class="text-center px-6 py-4">
            {{ project.projectCount }}
          </td>
          <td class="text-center px-6 py-4">
            {{ getProjectGlobalScore(project.fullName) }}
          </td>
        </tr>
      </tbody>
    </table>
  
    <div :disabled="fetching" class="w-full relative block shadow-md rounded-b-lg bottom-0 bg-white p-1">
      <t-pagination
        v-if="totalPages > 1"
        ref="projectsPagination"
        :disabled="fetching"
        :value="numPage"
        :total-items="totalPages * pagecount"
        :per-page="pagecount"
        :class="{
          'ml-auto': !false,
          'mx-auto': false
        }"
        @change="getPage"
      />
      <div v-if="projectData.length == 0" class="flex flex-row justify-center text-center items-center gap-5">
        Aucun projet disponible
      </div>
      <div v-else class="flex flex-row justify-center text-center items-center gap-5">
        <button
          class="w-1/4 text-center text-sm py-3 rounded bg-raphal-traiter text-white hover:bg-raphal-termine focus:outline-none mt-1"
          @click="exportCsv"
        >
          Export CSV
        </button>
        <button
          class="w-1/4 text-center text-sm py-3 rounded bg-raphal-traiter text-white hover:bg-raphal-termine focus:outline-none mt-1"
          @click="exportCsvAdvanced"
        >
          Export CSV avancé
        </button>
      </div>
    </div>
  </div>
</template>
  
<script>
  import gql from 'graphql-tag';
  import { apolloProjectClient } from '../vue-apollo';
  import { mapGetters } from 'vuex';
  
  export default {
    name: 'TableListProjects',
    data() {
      return {
        projectData: [],
        numPage: 1,
        pagecount: 10,
        fetching: false,
        sortOrder: 'asc',
        sortBy: null,
      };
    },
    computed: {
      ...mapGetters({
          isAuth: 'Auth/isAuth',
          user: 'Auth/user',
          roleName: 'Auth/roleUser',
      }),
      paginatedProjects() {
          if (!this.projectData) {
              return [];
          }

          const startIndex = (this.numPage - 1) * this.pagecount;
          const endIndex = this.numPage * this.pagecount;

          return this.projectData.slice(startIndex, endIndex);
      },
      totalPages() {
        const uniqueOperateurs = new Set(this.projectData.map(project => project.fullName));
        return Math.ceil(uniqueOperateurs.size / this.pagecount);
      }
    },
    watch: {
      '$parent.userFilter': {
        handler(newValue, oldValue) {
          if (newValue !== oldValue) {
            this.fetchData();
          }
        },
        immediate: true,
      },
      '$parent.period': {
        handler(newValue, oldValue) {
          if (newValue !== oldValue) {
            this.fetchData();
          }
        },
        immediate: true,
      },
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      getPage(num) {
        this.numPage = num;
      },
      formatDateToYYYYMMDD(date) {
        if (!date) return '';
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      async fetchData() {
        try {
          this.fetching = true;

          const operateur = this.$parent.userFilter;
          const startDate = this.$parent.period && this.$parent.period[0] ? this.$parent.period[0].toISOString() : '';
          const endDate = this.$parent.period && this.$parent.period[1] ? this.$parent.period[1].toISOString() : '';

          let { data } = await apolloProjectClient.query({
            query: gql`
              query ($operateur: String, $startDate: String, $endDate: String) {
                projectsAndUsers(
                  operateur: $operateur
                  startDate: $startDate
                  endDate: $endDate
                ) {
                  id
                  fullName
                  projectCount
                  projectData {
                    id
                    nom
                    dateDepot
                    etatGeoref
                    commentaire
                    insee
                    etatReceptionPhotos
                    etatPreTraitement
                    etatTraitement
                    indiceIonoSphere
                    operateurTerrain
                    scores
                  }
                }
              }
            `,
            fetchPolicy: 'network-only',
            variables: {
              operateur,
              startDate: this.formatDateToYYYYMMDD(startDate),
              endDate: this.formatDateToYYYYMMDD(endDate),
            },
          });

          this.projectData = data.projectsAndUsers;
          this.fetching = false;
        } catch (err) {
          console.error('Erreur : ', err);
          this.fetching = false;
          this.projectData = [];
        }
      },
      sanitizeText(text) {
        if (!text) return text;
        
        return text.replace(/[^a-zA-Z0-9 ().îïéàèâ-]/g, '_');
      },
      exportCsv() {
        const headers = ['Utilisateur', 'Nombre de projets', 'Pourcentage de réussite'];

        const rows = this.projectData.map(project => [
          this.sanitizeText(project['fullName']),
          project['projectCount'],
          this.getProjectGlobalScore(project['fullName'])
        ]);

        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += headers.join(',') + '\n';
        rows.forEach(row => {
          csvContent += row.join(',') + '\n';
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'data_stats.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      exportCsvAdvanced() {
        const headers = ['Nom', 'Etat', 'Date', 'Opérateur', 'Commentaire', 'INSEE', 'Activité Ionosphérique'];

        const projectData = []
        this.projectData.map((datas) => {
          if (datas['projectData'].length > 0) {
            datas['projectData'].map((data) => {
              projectData.push(data)
            })
          }
        });

        const sortedProjects = projectData.sort((a, b) => {
          if (a.nom < b.nom) return -1;
          if (a.nom > b.nom) return 1;
          return 0;
        });

        const rows = sortedProjects.map(project => [
          this.sanitizeText(project.nom),
          this.$raphalUtils.getProjectState(project),
          project.dateDepot,
          this.sanitizeText(project.operateurTerrain),
          this.sanitizeText(project.commentaire),
          project.insee,
          this.$raphalUtils.getIonoText(project.indiceIonoSphere)
        ]);

        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += headers.join(',') + '\n';
        rows.forEach(row => {
        csvContent += row.join(',') + '\n';
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'advanced_data_stats.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      sortByProjects() {
        this.sortBy = 'projects';
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';

        this.getSortedProjects();
      },
      sortByScore() {
        this.sortBy = 'score';
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';

        this.getSortedProjects();
      },
      getSortedProjects() {
        let sortedProjects = [...this.projectData];
        if (this.sortBy === 'projects') {
          sortedProjects = sortedProjects.sort((a, b) => {
            const countA = a['projectCount'];
            const countB = b['projectCount'];
            return this.sortOrder === 'asc' ? countA - countB : countB - countA;
          });
        } else if (this.sortBy === 'score') {
          sortedProjects = sortedProjects.sort((a, b) => {
            const scoreA = this.getProjectGlobalScore(a.fullName);
            const scoreB = this.getProjectGlobalScore(b.fullName);
            return this.sortOrder === 'asc' ? scoreA - scoreB : scoreB - scoreA;
          });
        }

        this.projectData = sortedProjects;

        return sortedProjects;
      },
      getProjectGlobalScore(operateur) {
        let totalScore = 0;
        let projectCount = 0;

        this.projectData.forEach(project => {
          if (project['projectData'].length > 0) {
            if (project['fullName'] == operateur) {
              const score = JSON.parse(project['projectData'][0].scores).global;
              totalScore += score;
              projectCount += 1;
            }
          }
        });

        return projectCount > 0 ? Math.round(totalScore / projectCount) : 0;
      }
    },
  };
</script>
  