<template>
  <div>
    <query-builder v-model="query" :config="config">
      <template #groupOperator="props">
        <group-operator-slot :group-operator="props" />
      </template>

      <template #groupControl="props">
        <group-ctrl-slot
          :group-ctrl="props"
          :delete-filters="deleteFilters"
          :export-projects="exportProjects"
          :search-projects="search"
        />
      </template>

      <template #rule="props">
        <rule-slot :rules="config.rules" :rule-ctrl="props" />
      </template>
    </query-builder>
  </div>
</template>

<script>
import QueryBuilder from 'query-builder-vue';

import GroupOperatorSlot from './FilterGroupCtrlSlotPagination.vue';
import GroupCtrlSlot from './FilterGroupCtrlSlotPagination.vue';
import RuleSlot from './FilterRuleSlot.vue';
import Input from './FilterInput.vue';
import Radio from './FilterRadio.vue';
import Select from './FilterSelect.vue';
import Score from './FilterScores.vue';

export default {
  components: {
    QueryBuilder,
    GroupOperatorSlot,
    GroupCtrlSlot,
    RuleSlot
  },
  props: {
    exportProjects: { type: Function, default: null },
    searchProjects: { type: Function, default: null },
    initListProjects: { type: Function, default: null }
  },
  data() {
    return {
      query: {
        operatorIdentifier: 'AND',
        children: []
      },
      grpsearch: false
    };
  },
  computed: {
    config() {
      return {
        operators: [
          {
            name: 'Et',
            identifier: 'AND'
          }
        ],
        rules: [
          {
            identifier: 'commande',
            name: 'COMMANDE',
            component: Input,
            selected: false
          },
          {
            identifier: 'typeTraveau',
            name: 'TYPE DE TRAVAUX',
            component: Input,
            selected: false
          },
          {
            identifier: 'sectionSize',
            name: 'Section équipement',
            component: Input,
            selected: false
          },
          // {
          //   identifier: "commentaire",
          //   name: "COMMENTAIRE",
          //   component: Input,
          //   selected:false,
          // },
          {
            identifier: 'grilleRepiquage',
            name: 'MARQUAGE/PIQUETAGE',
            component: Radio,
            initialValue: true,
            selected: false
          },
          {
            identifier: 'longueur',
            name: 'LONGUEUR PROJET',
            component: Input,
            selected: false
          },
          {
            identifier: 'modelApn',
            name: 'Modèle smartphone',
            component: Input,
            selected: false
          },
          // {
          //   identifier: "listImages",
          //   name: "NBR IMAGES PROJET",
          //   component: Input,
          //   selected:false,
          // },
          {
            identifier: 'georeferenced',
            name: 'géoréférencement',
            component: Radio,
            initialValue: true,
            selected: false
          },
          // {
          //   identifier: 'operateurTerrain',
          //   name: 'Opérateur terrain',
          //   component: Input,
          //   selected: false
          // },
          {
            identifier: 'commune',
            name: 'Commune',
            component: Input,
            selected: false
          },
          // {
          //   identifier: 'insee',
          //   name: 'INSEE',
          //   component: Input,
          //   selected: false
          // },
          {
            identifier: 'rtk',
            name: 'RTK',
            component: Radio,
            initialValue: true,
            selected: false
          },
          {
            identifier: 'iono',
            name: 'Activité ionosphérique',
            component: Select,
            selected: false
          },
          {
            identifier: 'scoreCaptation',
            name: 'Score Captation',
            component: Score,
            selected: false
          },
          {
            identifier: 'scorePhotogrammetry',
            name: 'Score Projet',
            component: Score,
            selected: false
          }
        ],
        colors: ['hsl(88, 50%, 55%)', 'hsl(187, 100%, 45%)'],
        dragging: {
          disabled: false
        }
      };
    }
  },
  watch: {
    query: function () {
      this.config.rules = this.config.rules.map((rl) => {
        if (this.query.children.find((r) => r.identifier === rl.identifier) == null) {
          rl.selected = false;
        }
        return rl;
      });

      this.search();
    }
  },
  methods: {
    search() {
      this.searchProjects(this.query);
    },
    deleteFilters() {
      this.query.children = [];
      this.config.rules = this.config.rules.map((rl) => {
        rl.selected = false;
        return rl;
      });
      this.initListProjects();
    }
  }
};
</script>

<style lang="scss">
.query-builder-group__group-children {
  margin-left: 2rem !important;
  border: none !important;
  max-height: 147px !important;
  overflow-y: scroll !important;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}
.query-builder-group__group-children::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.query-builder-child__component {
  padding: 3px !important;
  border: 2px !important;
  border-color: black !important;
  background-color: white;
}
.query-builder-child__delete-child {
  top: 3px !important;
}
</style>
