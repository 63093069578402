<template>
  <div class="w-full h-full chart">
    <div v-if="showSelect" class="flex justify-center items-center pt-3 h-10">
      <select v-model="select_choice" class="text-sm">
        <option :value="0">
          Chiffres
        </option>
        <option :value="1">
          Pourcentage
        </option>
      </select>
    </div>
    <v-chart class="chart" :option="option" autoresize />
  </div>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart, LineChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent, ToolboxComponent } from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart
]);

export default {
  name: 'EchartBar',
  components: {
    VChart
  },
  props: {
    chartData: { type: [Object, Array], default: null },
    // eslint-disable-next-line vue/prop-name-casing
    chart_title: { type: String, default: '' },
    showSelect: { type: Boolean, default: false },
    showInsideLabel: { type: Boolean, default: false },
    type: { type: String, default: '' }
  },
  provide: {
    [THEME_KEY]: 'vintage'
  },
  data() {
    return {
      select_choice: 0,
      labelOption: {
        show: true,
        position: 'insideBottom',
        distance: 15,
        align: 'left',
        verticalAlign: 'middle',
        rotate: 90,
        formatter: ({ value, seriesName }) => {
          if (value == 0) {
            return '';
          } else {
            return this.showInsideLabel == true ? value + ' ' + seriesName : '';
          }
        },
        // formatter:  this.showInsideLabel==true  ? '{c}  {name|{a}}':  ''  ,
        fontSize: 16,
        rich: {
          name: {}
        }
      },
      option: {
        title: {
          text: this.chart_title,
          left: 'center'
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            saveAsImage: { show: true }
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: '{c}',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          }
        },
        color: ['#1d2f47', '#777777', '#99cccc', '#37A8DF', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        grid: {
          top: '12%',
          left: '1%',
          right: '10%',
          bottom: '0',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.chartData.xAxisData
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: []
      }
    };
  },
  watch: {
    select_choice: function () {
      this.getSeries();
      // this.chart_config.tooltip={ label: 'tooltipLabel' }
    }
  },
  mounted() {
    this.getSeries();
  },
  methods: {
    getSeries: function () {
      let series = [];
      for (var k in this.chartData) {
        let select = false;
        if (this.select_choice == 1) {
          select = k.endsWith('Percentage');
        } else {
          select = !k.endsWith('Percentage');
        }
        if (k != 'xAxisData' && select) {
          series.push({
            name: k.replace('Percentage', '').replace('data', '').replace('Echoue', 'Echoué'),
            type: this.type ? this.type : 'bar',
            barGap: 0,
            label: this.labelOption,
            emphasis: {
              focus: 'series'
            },
            data: this.chartData[k]
          });
        }
      }

      this.option.series = series;
    }
  }
};
</script>

<style scoped>
.chart {
  height: 350px;
}
</style>
